.prev{
  height: calc(100% - 100px);
  margin: auto;
  position: absolute;
  z-index: 2;
  outline: none;
  width: 100%;
  div{
    canvas{
      width: 100% !important;
      height: 100% !important;
    }
  }
}
@media (max-width: 576px), (max-height: 576px)
{
  .prev{
    height: calc(100%);

  }
}