.allpage {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content {
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise {
  margin: 0;
  padding: 0;
  height: fit-content;
  width: 200px;
  .logoentreprise {
    margin: 0;
    padding: 0px;
    //  border: 1px solid rgba(170, 170, 170, 0.178);

    max-height: 45px;
    min-height: 15px;
    min-width: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    //   justify-content: center;
    background-color: transparent;
    z-index: 3;
    a {
      height: 100%;
      max-width: 190px;
      max-height: 100%;
    }

    img {
      top: 0;
      max-width: 190px;

      position: absolute;
      max-width: 190px;
      max-height: 50px;
      min-height: 15px;
      min-width: 30px;

      //  margin: auto;
      //  filter: invert(1);
      // width: 110px;
    }
  }
  @media (max-width: 1200px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 48px;
      min-height: 20x;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      a {
        height: 100%;
        max-width: 160px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 48px;
        min-height: 20x;
        min-width: 25px;

        max-width: 160px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 992px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 48px;
      min-height: 35px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      a {
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 48px;
        min-height: 35px;
        min-width: 25px;

        max-width: 135px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 768px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 40px;
      min-height: 35px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      a {
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 40px;
        min-height: 35px;
        min-width: 25px;

        max-width: 135px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
}

.titlezone {
  height: calc(100% - 270px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  .nameentreprise {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    p {
      writing-mode: vertical-lr;
      text-orientation: mixed;
      transform: rotate(180deg);
      font-family: "Poppins";
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #cfcfcf;
    }
  }
}

.choose {
  height: auto;
  position: absolute;
  bottom: 107px;
  //Rajout 3D
  z-index: 5;
  position: absolute;
  //__
  padding: 0;
  margin: 0;

  .choosetype {
    height: 100%;
    padding: 0;
    margin: 0;
    @media (min-width: 576px) {
      flex: 0 0 190%;
      max-width: 190%;
    }
    @media (min-width: 768px) {
      flex: 0 0 160%;
      max-width: 160%;
    }
    @media (min-width: 992px) {
      flex: 0 0 140%;
      max-width: 140%;
    }
    @media (min-width: 1200px) {
      flex: 0 0 130%;
      max-width: 130%;
    }
    @media (min-width: 1400px) {
      flex: 0 0 120%;
      max-width: 120%;
    }
    .titlechange {
      height: 33%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;

      .title {
        font-weight: 500;
        color: black;
        font-size: calc(0.7vw + 1.7rem);
        height: calc(25px + 0.7vw);
        margin-bottom: 0.7vw;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      img {
        display: flex;
      }
      @media (min-width: 576px) {
        .arrow_background {
          display: flex;
          justify-content: center;
          background-color: rgba(236, 236, 236, 0.301);
          border: solid#dddddd40 1px;
          border-radius: 2px;
          min-width: 34px;
          min-height: 34px;
          cursor: pointer;
          margin-bottom: 0.7vw;
          img {
            transform: rotate(180deg);
          }
        }
        .arrow_background_opac {
          display: flex;
          justify-content: center;
          background-color: rgba(236, 236, 236, 0.8);
          border: solid#dddddd40 1px;
          border-radius: 2px;
          min-width: 34px;
          min-height: 34px;
          cursor: pointer;
          margin-bottom: 0.7vw;
          img {
            transform: rotate(180deg);
          }
        }
      }
    }

    @media (min-width: 576px) {
      .change {
        background-color: hsla(0, 0%, 92.5%, 0.301);
        padding-left: calc(15px + 1vw) !important;
        padding-right: calc(15px + 1vw) !important;
      }
      .changeOpac {
        background-color: hsla(0, 0%, 92.5%, 0.8);
        padding-left: calc(15px + 1vw) !important;
        padding-right: calc(15px + 1vw) !important;
      }
      .hidechoosedesktop {
        height: 0 !important;
        transition: 0.4s;
        display: none !important;
      }
      .viewchoosedesktop {
        height: max-content !important;
        transition: 0.4s;
        display: flex !important;
      }
    }

    .change,
    .changeOpac {
      margin-top: 3px !important;
      //  height: 33%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      //  height: 70%;
      display: flex;
      flex-wrap: wrap;
      transition: 0.4s;

      .typeselected {
        position: relative;
        h2 {
          color: #2b2b2b;
          font-family: "Poppins";
          font-size: calc(0.55vw + 1.05rem) !important;
          font-weight: 500 !important;
          padding: 0;
          margin: 0;
        }

        .changeimg {
          transform: scale(1.12) !important;
        }
      }
      .papertype {
        max-width: 100% !important;
        padding: 0 !important;
        margin-left: 5px !important;
        left: 0;
      }
      @media (min-width: 576px) {
        .papertype {
          margin-left: 0px !important;
        }
      }

      .typezone {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .type {
        max-width: calc(100% - 50px);
        padding: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding-left: 7px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        left: -7px;
        /* margin-bottom: 5px; */
        /* margin-top: 5px; */
        height: 45px;
        .silvercolor {
          position: absolute;
          left: calc(-15px - 0.5vw);
          width: 12px;
          height: 65%;
          border: 1px solid #aaaaaa;
        }
        .gold {
          background-color: gold;
        }
        .silver {
          background-color: silver;
        }
        .red {
          background-color: red;
        }
        .blue {
          background-color: blue;
        }
        .green {
          background-color: green;
        }
        h2 {
          color: #2b2b2b;
          font-family: "Poppins";
          font-size: calc(0.5vw + 1rem);
          font-weight: 400;

          padding: 0;
          margin: 0;
        }
        .imgpaper {
          border: 1px solid #707070;
          height: 25px;
          width: 40px;
        }
        @media (min-width: 576px) {
          .imgpaper {
            width: 30px;
          }
        }

        .changeimg {
          transform: scale(1.12) !important;
        }
      }
      @media (min-width: 576px) {
        .type {
          justify-content: start;
          left: 0;
          padding-left: 0px;
          img {
            position: relative !important;
            left: 0 !important;
            margin-right: 10px;
          }
        }
      }
      .typeColorVarnish {
        max-width: calc(100% - 50px);
        padding: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding-left: 7px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        left: -7px;
        /* margin-bottom: 5px; */
        /* margin-top: 5px; */
        height: 45px;
        .silvercolor {
          position: absolute;
          left: calc(-15px - 0.5vw);
          width: 12px;
          height: 65%;
          border: 1px solid #aaaaaa;
        }
        .gold {
          background-color: gold;
        }
        .silver {
          background-color: silver;
        }
        .red {
          background-color: red;
        }
        .blue {
          background-color: blue;
        }
        .green {
          background-color: green;
        }
        h2 {
          color: #565656;
          font-family: "Poppins";
          font-size: calc(0.5vw + 1rem);
          font-weight: 400;

          padding: 0;
          margin: 0;
        }
        .imgpaper {
          border: 1px solid #707070;
          height: 25px;
          width: 40px;
        }
        @media (min-width: 576px) {
          .imgpaper {
            width: 30px !important;
          }
        }

        .changeimg {
          transform: scale(1.12) !important;
        }
      }
      .separation {
        height: 1px;
        background-color: #d0d0d0;
        width: 50%;
        margin-top: 5%;
      }
      .type:hover {
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 5px;
        h2 {
          color: #2b2b2b;
          font-family: "Poppins";
          font-size: calc(0.52vw + 1.03rem);
          font-weight: 500;
        }
        .changeimg {
          animation: rotateanim 0.3s ease;
          transform: rotate(180deg);
        }
        .imgpaper {
          border: 1px solid #707070;
          transform: scale(1.03);
        }
      }
      .typeselected:hover {
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 5px;
        h2 {
          color: #2b2b2b;
          font-family: "Poppins";
          font-size: calc(0.52vw + 1.03rem);
          font-weight: 500;
        }
        .changeimg {
          animation: rotateanim 0.3s ease;
          transform: rotate(180deg);
        }
        .imgpaper {
          border: 1px solid #707070;
        }
      }
    }
  }
}
.view {
  position: absolute;
  right: 0px;
  width: 25px;
  cursor: pointer;
}
.white {
  color: white !important;
}

@keyframes rotateanim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.contactzone {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  height: 100px;
  @media (max-width: 1450px) {
    .contact {
      width: 170px !important;
    }
  }
  @media (max-width: 1200px) {
    .contact {
      width: 150px !important;
    }
  }

  .contact {
    height: max-content;
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 5;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    .contactus {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      img {
        position: absolute;
        padding-top: 5px;
        height: 60px;
        bottom: 0;
        left: calc(38vw);
      }
    }
  }
}

@keyframes devisnotvisible {
  0% {
    left: 0px;
  }
  100% {
    left: -450px;
  }
}
@keyframes devisvisible {
  0% {
    left: -450px;
  }
  100% {
    left: 0px;
  }
}
.divisvisible {
  left: 0px;
  animation: devisvisible 0.4s ease;
}
.devinotvisible {
  left: -450px;
  animation: devisnotvisible 0.2s ease;
}

@media (max-width: 576px) {
  .infoentreprise {
    position: fixed;
    left: 25px;
    top: 15px;
    z-index: 15;
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 40px;
      min-height: 30px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      a {
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;

        max-height: 40px;
        min-height: 30px;
        min-width: 25px;

        max-width: 135px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  .contact {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .white {
    color: black !important;
  }
  .choose {
    height: auto;
    position: fixed;
    bottom: 0;
    //Rajout 3D
    z-index: 5;

    //__
    background-color: #f7f7f7;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .choosetype {
      height: 100%;
      padding: 0;
      margin: 0;
      .titlechange {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;

        .title {
          font-weight: 500;
          color: black;
          font-size: calc(2.2rem);
          height: calc(25px + 0.7vw);
          margin-bottom: 0.7vw;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 80%;
        }
        img {
          width: 27px;
          cursor: pointer;
          display: block;
        }
      }

      .change,
      .changeOpac {
        margin-top: 3px !important;
        height: 33%;
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        height: 70%;
        display: flex;
        flex-wrap: wrap;
        .typeselected {
          position: relative;
          h2 {
            color: #2b2b2b;
            font-family: "Poppins";
            font-size: calc(1.9rem) !important;
            font-weight: 500 !important;
            padding: 0;
            margin: 0;
          }
          .viewseparation {
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #bcbcbc;
          }
          .changeimg {
            transform: scale(1.12) !important;
          }
        }
        .papertype {
          max-width: 100% !important;
          padding: 0 !important;
          margin-left: 0px !important;
          left: 0;
        }
        @media (min-width: 576px) {
          .papertype {
            margin-left: 0px !important;
            width: 30px !important;
          }
        }
        .typezone {
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .view {
            filter: invert(0.5);
          }
        }
        .type {
          max-width: calc(100% - 50px);
          padding: 0;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 8px;
          padding-left: 7px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 10px;
          left: -7px;
          /* margin-bottom: 5px; */
          /* margin-top: 5px; */
          height: 45px;
          .silvercolor {
            position: absolute;
            left: calc(-15px - 0.5vw);
            width: 12px;
            height: 65%;
            border: 1px solid #aaaaaa;
          }
          .gold {
            background-color: gold;
          }
          .silver {
            background-color: silver;
          }
          .red {
            background-color: red;
          }
          .blue {
            background-color: blue;
          }
          .green {
            background-color: green;
          }
          h2 {
            color: #565656;
            font-family: "Poppins";
            font-size: calc(1.8rem);
            font-weight: 300;

            padding: 0;
            margin: 0;
          }
          .imgpaper {
            border: 1px solid #707070;
            height: 25px;
            width: 40px;
            margin-right: 25px;
          }

          .viewseparation {
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #bcbcbc;
          }

          .changeimg {
            transform: scale(1.12) !important;
          }
        }

        .typeColorVarnish {
          max-width: calc(100% - 50px);
          padding: 0;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          padding-left: 7px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 10px;
          left: -7px;
          /* margin-bottom: 5px; */
          /* margin-top: 5px; */
          height: 45px;
          .silvercolor {
            position: absolute;
            left: calc(-15px - 0.5vw);
            width: 12px;
            height: 65%;
            border: 1px solid #aaaaaa;
          }
          .gold {
            background-color: gold;
          }
          .silver {
            background-color: silver;
          }
          .red {
            background-color: red;
          }
          .blue {
            background-color: blue;
          }
          .green {
            background-color: green;
          }
          h2 {
            color: #565656;
            font-family: "Poppins";
            font-size: calc(1.8rem);
            font-weight: 300;

            padding: 0;
            margin: 0;
          }
          .imgpaper {
            border: 1px solid #707070;
            height: 25px;
            width: 40px;
          }
          .viewseparation {
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #bcbcbc;
          }
          .changeimg {
            transform: scale(1.12) !important;
          }
        }
        .separation {
          height: 1px;
          background-color: #d0d0d0;
          width: 50%;
          margin-top: 5%;
        }
        .type:hover {
          cursor: pointer;
          margin-top: 5px;
          margin-bottom: 5px;
          h2 {
            color: #2b2b2b;
            font-family: "Poppins";
            font-size: calc(1.9rem);
            font-weight: 500;
          }
          .changeimg {
            animation: rotateanim 0.3s ease;
            transform: rotate(180deg);
          }
          .imgpaper {
            border: 1px solid #707070;
            transform: scale(1.03);
          }
        }
        .typeselected:hover {
          cursor: pointer;
          margin-bottom: 5px;
          margin-top: 5px;
          h2 {
            color: #2b2b2b;
            font-family: "Poppins";
            font-size: calc(1.9rem);
            font-weight: 500;
          }
          .changeimg {
            animation: rotateanim 0.3s ease;
            transform: rotate(180deg);
          }
          .imgpaper {
            border: 1px solid #707070;
          }
        }
      }
    }
  }

  .viewchoose {
    display: flex !important;
  }
  .hidechoose {
    display: none !important;
  }

  .clickchoose {
    top: calc(100vh) !important;
  }
}
.fond {
  height: 100%;
  width: 130%;
  background-color: hsla(0, 0%, 92.5%, 0.301);
  top: 0;
  right: -15px;
  position: absolute;
  z-index: -1;
}
