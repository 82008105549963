.allpage,
.allpagemobile {
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.toptransparence {
  display: none;
}
@media (max-width: 576px), (max-height: 576px) {
  .toptransparence {
    display: block !important;
    height: 65px;
    width: 100%;
    background: linear-gradient(#f3f3f393 0%, #f3f3f377 15%, transparent);
    position: fixed;
    top: 0;
    z-index: 1;
  }
}
.toptitle {
  position: fixed;
  top: 0;
  left: 0;
  font-family: "Poppins";
  font-size: calc(0.6vw + 1.7rem);
  font-weight: 400;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  z-index: 1;

  p {
    z-index: 10;
    background-color: #f0f0f07c;
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    margin: 0;

    @media (min-width: 768px) {
      width: 420px;
    }
    @media (min-width: 992px) {
      width: 580px;
    }
    @media (min-width: 1200px) {
      width: calc(520px + 15%);
    }
    @media (min-width: 1500px) {
      width: calc(59% - 100px);
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
.content {
  height: calc(100% - 45px);
  padding: 22px;
  padding-bottom: calc(20px + 8px);
  padding-top: calc(20px + 2px);
  margin: 0;
  overflow: hidden;
  padding-top: 70px;
}
.projet {
  // height: 33.333%;
  //max-height: 31.7%;
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px 0px #00000026;
  width: 100%;
  height: 100%;

  .image {
    padding: 0;
    cursor: pointer;
    top: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    //  background-color: white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.projetHover:hover {
  /* Permet de réaliser l'animation agrandire */
  //animation: crescendo 0.3s ease;
  //transform: scale(1.037) !important;
  box-shadow: 0px 4px 6px 0px #0000003b;
  .image {
    /* Permet de réaliser l'animation zoom sur l'image */
    a {
      height: 100%;
      width: 100%;
      animation: crescendo 0.3s ease;
      transform: scale(1.037) !important;
      img {
        animation: crescendoimg 0.7s ease-out;
        transform: scale(1.05) !important;
      }
    }
  }
}
@keyframes crescendo {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.037);
  }
}
@keyframes crescendoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  /*.projet:last-child {
    display: none;
  }*/
  .projet {
    height: 22%;
    //max-height: 21%;
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    //max-width: 48%;
  }
}

/*@media (min-width: 992px) {
  .projet {
    max-width: 31.7%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .projet {
    flex: 0 0 50%;
    max-width: 48%;
  }
}*/

@media (max-width: 768px) {
  .content {
    height: calc(100% - 100px);
  }
}

@media (min-width: 576px) {
  .allpage {
    display: block;
  }
  .allpagemobile {
    display: none;
  }
}

@media (max-width: 576px) {
  .allpage {
    display: none;
  }
  .allpagemobile {
    overflow: scroll;
    padding-bottom: 110px;
    transition: 0.4s;
    .container {
      height: auto;
      overflow: hidden;
    }
  }

  .projet {
    height: auto;
    max-width: 100%;
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .content {
    padding: 0px;
    overflow: hidden;
  }
  .allpagemobile {
    display: block;
  }
}
