@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
@keyframes hidde {
  0% {
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 1;
    display: flex;
  }

  99% {
    display: flex;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
    display: none;
    opacity: 0;
  }
}
.chargement3Dhide {
  animation: hidde 0.3s ease;
  display: none;
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
}
.chargement3D {
  position: absolute;
  z-index: 3;

  height: 100%;

  justify-content: center;
  align-items: center;
  padding-bottom: 80px !important;
  padding-left: 15px !important;
  flex-wrap: nowrap;
  div {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      .loader {
        display: inline-block;
        width: 7vw;
        height: 7vw;
        min-width: 30px;
        min-height: 30px;
        max-width: 65px;
        max-height: 65px;
        margin-bottom: 40px;
        position: relative;
        border: 4px solid #bcbdc3;
        top: 50%;
        animation: loader 2s infinite ease;
      }

      .loader_inner {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        background-color: #bcbdc3;
        animation: loader-inner 2s infinite ease-in;
      }
    }
  }
}
