.allpage {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content {
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise {
  margin: 0;
  padding: 0;
  height: fit-content;
  .logoentreprise {
    margin: 0;
    padding: 0;
    img {
      width: 75%;
      min-width: 100px;
      max-width: 198px;
    }
  }
}

.filterzone {
  height: 43px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 1450px) {
    .filter {
      width: 170px !important;
    }
  }
  @media (max-width: 1200px) {
    .filter {
      width: 150px !important;
    }
  }
  .close {
    border: 0.5px solid #707070;
    height: 35px;
    position: relative;
    cursor: pointer;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    .closeimg {
      position: absolute;
      height: 100%;
      width: 100% !important;
      padding: 7px;

      // Pour le 3D
      position: absolute;
      z-index: 3;
    }
  }
}
.socialzone {
  display: none;

  width: 100%;
  //  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
  position: absolute;
  top: 0;
  .social {
    height: 25%;
    max-height: 200px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 150px;
    .icon {
      font-family: "Poppins";
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #cfcfcf;
      display: flex;
      justify-content: flex-end;
      height: 20px;

      a {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        img {
          position: absolute;
          z-index: 3;
          cursor: pointer;
          right: 0;
          width: 19px;
        }
        img:hover {
          filter: brightness(0%);
          position: absolute;
          z-index: 3;
          cursor: pointer;
        }
      }
      img {
        position: absolute;
        z-index: 3;
        cursor: pointer;
        right: -1px;
        width: 21px;
      }
      img:hover {
        filter: brightness(0%);
        position: absolute;
        z-index: 3;
        cursor: pointer;
      }
    }
    .iconw {
      font-family: "Poppins";
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #cfcfcf;
      display: flex;
      justify-content: flex-end;
      a {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        img {
          position: absolute;
          z-index: 3;
          cursor: pointer;
          right: 0;
          width: 20px;
          margin-right: 1px;
        }
        img:hover {
          filter: brightness(200%);
          position: absolute;
          z-index: 3;
          cursor: pointer;
        }
      }
      img {
        position: absolute;
        z-index: 3;
        cursor: pointer;
        right: -1px;
        width: 22px;
        margin-right: 1px;
      }
      img:hover {
        filter: brightness(200%);
        position: absolute;
        z-index: 3;
        cursor: pointer;
      }
    }
  }
}

.colorzone {
  padding: 0;
  margin: 0;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 130px;
  right: 110px;
  .color {
    position: absolute;
    z-index: 3;
    .colorimg {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .choosecolor {
      position: absolute;
      z-index: 3;
      width: 35px;
      height: 35px;
      visibility: hidden;
      overflow: hidden;
      flex-wrap: wrap;
      display: flex;
      right: 0;
      top: 0;
      .onecolor {
        width: 40px;

        height: 35px;
      }
    }
  }

  .color:hover {
    .colorimg {
      filter: brightness(95%);
    }
    .choosecolor {
      width: 360px;
      right: 35px;
      overflow: visible;
      visibility: visible;
      .onecolor {
        width: 35px;
        height: 35px;
        cursor: pointer;
        display: flex;
        position: relative;
        align-items: center;
        .selected {
          background-color: transparent;
          border: 1px solid blue;
          height: 28px;
          width: 28px;
          border-radius: 50px;
          position: absolute;
          z-index: 3;
          left: -4px;
        }
        .black {
          background-color: black;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .grey {
          background-color: gray;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .white {
          background-color: white;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .studio {
          //background-color: url(/public/envMap/colorful_studio.jpg);
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .outdoor1 {
          //background-color: url("/public/envMap/outdoor_1.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .outdoor2 {
          //background-color: url("public/envMap/outdoor_2.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .outdoor4 {
          //background-color: url("public/envMap/outdoor_4.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .outdoor3 {
          //background-color: url("public/envMap/indoor.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .indoor2 {
          //background-color: url("public/envMap/indoor2.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
        .vignes {
          //background-color: url("public/envMap/vignes.jpg");
          height: 20px;
          width: 20px;
          border-radius: 50px;
          position: absolute;
          z-index: 4;
          border: 1px solid #656565;
          left: 0px;
        }
      }
      .onecolor:hover {
        .scale {
          transform: scale(1.12);
        }
      }
    }
  }
}

.foldzone {
  padding: 0;
  margin: 0;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 130px;
  z-index: 10;
}

.contactzone {
  position: absolute;
  bottom: 0;
  height: 100px;
  padding: 0;
  margin: 0;
  @media (max-width: 1650px) {
    .contact {
      width: 164px !important;
    }
  }
  @media (max-width: 1200px) {
    .contact {
      width: 150px !important;
    }
  }
  .contact {
    height: max-content;
    border: 0.5px solid #707070;
    height: 40px;
    position: absolute;
    z-index: 5;
    bottom: 30px;
    cursor: pointer;
    width: 200px;
    right: 0;
    .contactus {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      text-align: center;
    }
    .contactus:hover {
      font-weight: 500;
    }
  }

  .learnMore {
    height: max-content;
    height: 40px;
    position: absolute;
    z-index: 5;
    bottom: 30px;
    cursor: pointer;
    width: 200px;
    right: 0;

    .sub {
      background-color: #0193cc;
      color: white;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      text-align: center;
    }
    .contactus:hover {
      font-weight: 500;
    }
  }
}

//title
.icon {
  div {
    height: 22px;
    width: 22px;
  }
  a {
    height: 22px;
    width: 22px;
    div {
      height: 22px;
      width: 22px;
    }
    [data-title]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
      display: block;
      height: 26px;
    }
    [data-title]:after {
      content: attr(data-title);
      background-color: black;
      color: white;
      font-size: 15px;
      position: absolute;
      padding: 2px 10px 2px 10px;
      bottom: -2px;
      left: inherit;
      right: 40px !important;
      white-space: nowrap;
      border-radius: 2px;

      width: auto;

      border: none;
      z-index: 1000;
      visibility: hidden;
      font-family: "Poppins";
      font-weight: 500;
    }
    [data-title] {
      position: absolute;
      z-index: 500;
    }
  }
  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
    display: block;
    height: 26px;
  }
  [data-title]:after {
    content: attr(data-title);
    background-color: black;
    color: white;
    font-size: 15px;
    position: absolute;
    padding: 2px 10px 2px 10px;
    bottom: -2px;
    left: inherit;
    right: 40px !important;
    white-space: nowrap;
    border-radius: 2px;

    width: auto;

    border: none;
    z-index: 1000;
    visibility: hidden;
    font-family: "Poppins";
    font-weight: 500;
  }
  [data-title] {
    position: absolute;
    z-index: 500;
  }
}

.invert {
  filter: invert(1);
}

@keyframes devisnotvisible {
  0% {
    right: 0px;
  }
  100% {
    right: -450px;
  }
}
@keyframes devisvisible {
  0% {
    right: -450px;
  }
  100% {
    right: 0px;
  }
}
.divisvisible {
  right: 0px;
  animation: devisvisible 0.4s ease;
}
.devinotvisible {
  right: -450px;
  animation: devisnotvisible 0.2s ease;
}

@media (max-width: 576px) {
  .socialzone {
    display: none;
  }
  .colorzone {
    bottom: 105px;
    right: 20px;
    .color:hover {
      .choosecolor {
        transform: rotate(90deg);
        right: -100px;
        top: -145px;
      }
    }
  }
  .contactzone {
    position: fixed;
    bottom: 0;
    padding: 0;
    margin: 0;
    bottom: 140px;

    height: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    .contact {
      height: max-content;
      border: 2px solid #707070;
      height: 40px;
      position: initial;
      cursor: pointer;
      width: auto !important;
      border-radius: 40px;
      padding-left: 10px;
      padding-right: 10px;
      p {
        font-weight: 600;
        color: #1d1d1d;
      }
    }
  }

  .black {
    .contact {
      background-color: #222222;

      p {
        color: white;
      }
    }
  }
  .gris {
    .contact {
      background-color: #dfdfdf;

      p {
        color: rgb(0, 0, 0);
      }
    }
  }
  .white {
    .contact {
      background-color: #ffffff;

      p {
        color: rgb(0, 0, 0);
      }
    }
  }

  .filterzone {
    .close {
      margin: 0;
      padding: 0;
      height: 20px;
      top: 22px;
      right: 25px;
      border: none;
      position: fixed;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
      .closeimg {
        height: 18px;
        cursor: pointer;
        width: auto !important;
        padding: 0;

        z-index: 3;
      }
    }
  }
}
