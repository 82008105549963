.plierdeplier {
  border-radius: 6px;
  position: fixed;
  z-index: 2;
  width: 100px !important;
  max-height: calc(100% - 40px);
  right: 6vw;
  background-color: white;
  height: 40px;
  border: 0.5px solid rgba(0, 0, 0, 0.301);
  margin: 0;
  padding: 0;
  bottom: 127px;

  .button {
    margin: 0;
    padding: 0;
    width: 49px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      height: 27px;
      opacity: 0.3;
      transition: 0.2s;
    }
  }
  .selected {
    position: absolute;
    width: 49px;
    height: 100%;
    z-index: -1;
    background-color: #8a8a8a;
    border: 1px solid white;
    border-radius: 6px;
    transition: 0.2s;
  }
  .selectedbtn {
    img {
      opacity: 1 !important;
      filter: invert(1);
    }
  }
  .plierselected {
    left: 50px;
  }
  .deplierselected {
    left: 0;
  }
}
