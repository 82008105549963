.allpage{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  .container{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content{
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise{
  margin: 0;
  padding: 0;
  height: fit-content;
  .logoentreprise{
    margin: 0;
    padding: 0;
    img{
      width: 75%;
      min-width: 100px;
      max-width: 198px;
    }
  }
}




.filterzone{
  height: 43px;
  padding: 0;
  margin: 0;
display: flex;
align-items: flex-end;
justify-content: flex-end;
  @media (max-width: 1450px)
  {
    .filter{
      width: 170px !important;
    }
  }
  @media (max-width: 1200px)
    {
      .filter{
       width: 150px !important;
    }
  }
  .filter{
    height: max-content;
    border: 0.5px solid #707070;
    height: 40px;
    position: relative;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text{
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
    .filterimg{
      position: absolute;
      left: 15px;
      width: max-content;
    }
    .arrow{
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 13px;
      padding-bottom: 13px;
      border-left: 0.5px solid #707070;
      filter: invert(0.35);
    }
    .arrow:hover{

      filter: invert(0);
    }
    .text:hover{

      font-weight: 500;
    }
  }
  @media (min-width: 768px)
    {
      .filter{
      .filterimg{
        position: absolute;
        left: 15px;
        width: max-content;
        display: none;
      }
    
  }
}
  @media (max-width: 768px), (max-height: 576px)
    {
      .filter{
        width: 50px !important;
        border: none !important;
       .text{
        margin: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        display: none;
      }
      .filterimg{
        position: absolute;
        left: 15px;
        width: max-content;
      }
      .arrow{
        position: absolute;
        right: 15px;
        width: max-content;
        display: none;
      }
    }
  }

}

@media (min-width:768px){
  .socialzone{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0;
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
    .social{
      height: 25%;
      max-height: 200px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      min-height: 150px;
      bottom: initial;
      .icon{
        font-family: 'Poppins';
        font-size: calc(0.3vw + 1rem);
        padding: 0;
        margin: 0;
        color: #CFCFCF;
        display: flex;
        justify-content: flex-end;
        height: 20px;
        opacity: 0.6;

  
      }
    }
  }
  
}

.socialzone{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-end;
  padding: 0;
  margin: 0;
  height: 100%;
  position: absolute;
  top: 0;
  .social{
    height: 25%;
    max-height: 200px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    min-height: 150px;
    bottom: 120px;
    .icon{
      font-family: 'Poppins';
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #CFCFCF;
      display: flex;
      justify-content: flex-end;
      height: 20px;
      opacity: 0.6;
      a{
        height: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        img{
          position: absolute;
          z-index: 3;
          cursor: pointer;
          right: 0;
          width: 19px;
          margin-right: 1px;
        }
        img:hover{
          filter: brightness(0%);
          position: absolute;
          z-index: 3;
          cursor: pointer;
        }


      }
      img{
        position: absolute;
        z-index: 3;
        cursor: pointer;
        right: -1px;
        width: 21px;
        margin-right: 1px;
      }
      img:hover{
        filter: brightness(0%);
        position: absolute;
        z-index: 3;
        cursor: pointer;
      }

    }
  }
}



.contactzone {
  position: absolute;
  bottom: 0;
  height: 100px;
  padding: 0;
  margin: 0;
  @media (max-width: 1450px) {
    .contact {
      width: 170px !important;
    }
  }
  @media (max-width: 1200px) {
    .contact {
      width: 150px !important;
    }
  }
  @media (max-width: 992px) {
    .contact {
    //  left: -10px !important;
    }
  }
  .contact {
    height: max-content;
    border: 0.5px solid #707070;
    height: 40px;
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    right: 0;
    width: 200px;
    right: 0;
    .contactus {
      height: 100%;
      width: 100%;
      display: flex;

      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;

      justify-content: center;
    }
    .contactus:hover {
      font-weight: 500;
    }
  }
}



.displaynone{
  z-index: -1;
  position: absolute;
  opacity: 0;
  width: 0;
  cursor: default;

}

//title
.icon{
  div{
    height: 22px;
    width: 22px;
  }
  a{
    height: 22px;
    width: 22px;
    div{
      height: 22px;
      width: 22px;
    }
    [data-title]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
      display: block;
      height: 26px;
    }
    [data-title]:after {
      content: attr(data-title);
      background-color: black;
      color: white;
      font-size: 15px;
      position: absolute;
      padding: 2px 10px 2px 10px;
      bottom: -2px;
      left: inherit;
      right: 40px !important;
      white-space: nowrap;
      border-radius: 2px;
    
      width: auto;
    
      border: none;
      z-index: 1000;
      visibility: hidden;
      font-family: "Poppins";
      font-weight: 500;
    }
    [data-title] {
      position: absolute;
      z-index: 500;
    }
  }
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  display: block;
  height: 26px;
}
[data-title]:after {
  content: attr(data-title);
  background-color: black;
  color: white;
  font-size: 15px;
  position: absolute;
  padding: 2px 10px 2px 10px;
  bottom: -2px;
  left: inherit;
  right: 40px !important;
  white-space: nowrap;
  border-radius: 2px;

  width: auto;

  border: none;
  z-index: 1000;
  visibility: hidden;
  font-family: "Poppins";
  font-weight: 500;
}
[data-title] {
  position: absolute;
  z-index: 500;
}

}

.filterzone{
  div{

    [data-title]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
      display: block;
      height: 26px;
    }
    [data-title]:after {
      content: attr(data-title);
      background-color: black;
      color: white;
      font-size: 15px;
      position: absolute;
      padding: 2px 10px 2px 10px;
      bottom: -35px;
      left: inherit;
      right: 0 !important;
      white-space: nowrap;
      border-radius: 2px;
    
      width: auto;
    
      border: none;
      z-index: 1000;
      visibility: hidden;
      font-family: "Poppins";
      font-weight: 500;
    }
    [data-title] {
      position: relative;
      z-index: 500;
    }
  }
}


  @media (max-width: 576px), (max-height: 576px)
  {
    .filterzone{
      width: 32px !important;
      position: fixed;
      right: 15px;
      top: 10px;
      z-index: 10;
      display: flex;
      align-items: center;
      height: 35px;
    }
    .filter{
      position: fixed;
      width: 30px !important;
      height: 30px !important;
      border: none !important;

        .filterimg{
          position: absolute;
          left: 5px !important;
          width: max-content;
        }
      }
  .icon a [data-title] {
    display: none !important;
}
.socialzone .social .icon a img {
  display: none !important;
  }
  .contact{
    display: none;
    .contactus{
      display: none;
      img{
        display: none;
      }
    }
  }
}