.bottom{
  padding: 0;
  margin: 0;
  height: 100px;
  position: fixed;
  z-index: -1;
  left: 0;
  bottom: 0;
  background: radial-gradient(#ececec66, #efefef66);

}
@media (max-width: 576px), (max-height: 576px)
{
  .bottom{
    height: 110px !important;
    z-index: 1 !important;
    background: linear-gradient(#ededed, #ebebeb);
  }
  .btnmenumobile{

    border-top: 1px solid rgba(206, 206, 206, 0.562);

  }
}
@keyframes viewbottom{
  0% {bottom: -100vh;}
  100% {bottom: 0vh;}
}
@keyframes hidebottom{
  0% {bottom: 0vh;}
  100% {bottom: -100vh;}
}

.viewbottom{
  bottom: 0vh;
  animation: viewbottom 0.4s ease;
}

.hidebottom{
  bottom: -100vh;
  animation: hidebottom 0.2s ease;
}
@media (min-width: 576px)
{

  .menumobile{

    display: none !important;

  }
  .btnmenumobile{

    display: none !important;

  }
}


.btnmenumobile{
  height: 30px;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;
  border-top: 1px solid rgba(206, 206, 206, 0.562);
  img{
    height: 32px;
    margin-right: 15px;
  }
}

.menumobile{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #dfdfdf;

  position: fixed;

  z-index: 13;

  .closemenumobile{
    margin: 0;
    padding: 0;
    height: 20px;
    position: absolute;
    top: 22px;
    right: 25px;
    img{
      height: 18px;
      cursor: pointer;
    }

  }
  .titlezone{
    top: calc(40px + 10%);
    width: 100%;
    margin: 0;
    padding: 0;
    height: 30px;
    position: absolute;
    p{
      text-align: center;
      font-family: 'Poppins';
      font-size: 22px;
      font-weight: 500;
    }

  }
  .btncontact{
    top: calc(70px + 20%);
    width: 100%;
    margin: 0;
    padding: 0;
    height: 130px;
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    div{
      margin: 0;
      padding: 0;
      width: 70%;

      height: 50px;
      a{
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid black;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        .image{
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 24px;
          }
          
        }
        p{
          margin: 0;
          padding: 0;
          width: 75%;
          text-align: center;
          font-family: 'Poppins';
          font-size: 15px;
          font-weight: 500;
        }
        .tel{
          font-size: 15px;
        }
      }

    }
  }
  .social{
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: calc(10px);

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    p{
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
      font-family: 'Poppins';
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 20px;
      height: 30px;
    }
    .icon{
      height: 30px;

      margin: 0;
      margin-bottom: 25px;
      padding: 0;
      display: flex;
      justify-content: center;
      a{
        margin-left: 25px;
        margin-right: 25px;
        img{
          height: 25px;
        }
      }
    }
    .brand{
      p{
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        font-family: 'Poppins';
        font-size: 15px;
        font-weight: 500;
        height: 30px;
        a{
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

  }
}