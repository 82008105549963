/* =================================================

Name:  Ctatlogue CSS
Author: MGI Digital Technology, MGI Labs
Version: 1.0
Design and Developed by: MGI Labs

NB: Ce fichier contient le style de l'écran de création du Catalogue.

=================================================== */
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  width: 100%;
}
a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: none;
  text-transform: none;
}
.home {
  height: 100%;
  width: 100%;
  padding: 0;

  background: radial-gradient(
    rgb(255, 255, 255),
    rgb(233, 233, 233)
  );
  z-index: 50;
  position: absolute;
  .container {
    height: 100%;
    padding: 0;
    .principal {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}
.bottlemodule{
  margin: 0;
  padding: 0;
  position: fixed;
  top: 100px;
  right: 7vw;
  z-index: 100;
  width: 300px;
  background-color: rgba(236, 236, 236, 0.301);
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  .bottletopzone{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    p{
      margin: 0;
      padding: 0;
      font-family: 'Poppins';
      font-size: calc(0.6vw + 1rem);
      font-weight: 400;
    }
    .button{
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: max-content;
      
    .on{
      width: 55px;
      margin-right: 20px;
      cursor: pointer;
      position: absolute;
      right: 70px;
    }
    .more{
      width: 21px;
      cursor: pointer;
    }

    .close{
      width: 27px;
      cursor: pointer;

      transform: rotate(45deg);
    }
    }
  }
  .bottlebottomzone{
    margin: 0;
    padding: 0;
    position: relative;
//    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 5px;
    .moovelabel{
      margin: 0;
      padding: 0;
      width: 100px;
      img{
        width: 40px;
      }
      .labelposition{
        position: absolute;
        left: 35px;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 10px;
        z-index: 10;
        overflow: visible;

         input[type="range"] {
          transform: rotate(-90deg);
          width: 90px;
          z-index: 10;
          left: -10px;
          position: absolute;
          margin-bottom: -42px;
          opacity: .7;
          background: #aaa;
          height: 3px;
          outline: none;
        }

        input[type="range"]:hover {
        transform: rotate(-90deg);
        width: 90px;
        z-index: 10;
        left: -10px;
        position: absolute;
        margin-bottom: -42px;
        opacity: .85;
        background: #aaa;
        height: 3px;
        }
   /* 
      input[type="range"]::-webkit-slider-thumb {
        background: black;
        border-radius: 100px;
        opacity: 1;
        height: 40px;
        width: 20px;
      }
    
     input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 30px;
        background: black;
        background-color: rgb(134, 134, 134);
        box-shadow: 0px 2px 10px -2px black(1);
        position: relative;
        z-index: 2;
        box-shadow: 1.1px 1.1px 6.8px rgba(0, 0, 0, 0),
          0px 0px 1.1px rgba(13, 13, 13, 0);
        border: 0px solid #000000;
       // background: black;
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
      } */

      }






      /*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {


    -webkit-appearance: none;
  //  background-color: #9a905d;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    width: 20px;
    -webkit-appearance: none;
  //  color: #13bba4;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 40px;
    -webkit-appearance: none;
    height: 6px;
    margin-top: 2px;
    border-radius: 5px;

    background: #434343;
    cursor: pointer;
  //  box-shadow: -80px 0 0 80px #43e5f7;
  }

}

    }
    .allbottle{
      margin: 0;
      padding: 0;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 120px;
      @media (min-width:576px){
        width: 112px;
        position: absolute;
        right: 0;
        bottom: 10px;
        height: 118px;
      }
    }
    
  }
}
.displaynone{
  display: none;
}
.bottle_button {
  position: relative;
  top: 0;
  right: 0;
  z-index: 100;
  cursor: pointer;
  margin-top: 10px;
  display: flex;

  img {
    height: 55px;
    width: 55px;
    opacity: 0.2;
  }
  img:hover {
    opacity: 0.6;
  }
  .selectedbottle{
    opacity: 0.6;
  }
}
@media (min-width:576px){
  .bottle_button {

    margin-top: 0px;
  
    img {
      height: 50px;
      width: 50px;
    }
  }
}

@media (min-width: 576px) and (max-width: 600px)  {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.choosetype {
  padding: 0;
  margin: 0;
  height: 100%;

}
.content {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.filtershare {
  padding: 0;
  margin: 0;
  height: 100%;
}
@keyframes padding0 {
  0%   {padding-left: 50px;}
  100% {padding-left: 0px;}
}
@keyframes padding30 {
  0%   {padding-left: 0px;}
  100% {padding-left: 50px;}
}
@keyframes devisnotvisible {
  0%   {width: calc(100% - 450px);}
  100% {width: 100%;}
}
@keyframes devisvisible {
  0%   {width: 100%;}
  100% {width: calc(100% - 450px);}
}
.divisvisible{
  width: calc(100% - 450px);
  animation: devisvisible 0.4s ease !important;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding30 0.2s ease;
      padding-left: 50px;
    }
  }

}
.devinotvisible{
  width: 100%;
  animation: devisnotvisible 0.2s ease;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding0 0.2s ease;
      padding-left: 0px;
    }
  }

}


.bottle{
  display: none;
}

@media (max-width: 1400px)
{
  .bottle{
    right: inherit;
    left: calc(50vw + 450px);
  }
}
@media (max-width: 1200px)
{
  .bottle{
    right: inherit;
    left: calc(50vw + 365px);
  }
}
@media (max-width: 992px)
{
  .bottle{
    right: inherit;
    left: calc(50vw + 245px);
  }
}
@media (max-width: 768px)
{
  .bottle{
    right: inherit;
    left: calc(50vw + 150px);
  }
}
@media (max-width: 576px)
{

  .bottlemodule{
    position: fixed;
    top: 70px;
    left: inherit;
    right: calc(20px);
    z-index: 100;
    cursor: pointer;
    display: block;
    width: 45px;
    .bottletopzone{
      display: none;
    }
    .bottlebottomzone{
      display: none;
    }
  }
  .choosetype {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 0px;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  .filtershare{
    width: 0px;
  }


  .bottle{
    position: fixed;
    top: 70px;
    left: inherit;
    right: calc(20px);
    z-index: 4;
    cursor: pointer;
    display: block;
    width: 45px;
  }
  .bottle_button {
    position: relative;
    top: 70px;
    right: calc(20px);
    z-index: 100;
    cursor: pointer;
    margin-top: 5px;
    top: 0;
    right: 0;
    img {
      height: 40px;
      width: 40px;
      opacity: 0.35;
    }
    img:hover {
      opacity: 0.7;
    }
  }
}