.allpage {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content {
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise {
  margin: 0;
  padding: 0;
  height: fit-content;
  width: 200px;
  .logoentreprise {
    margin: 0;
    padding: 0px;
    //  border: 1px solid rgba(170, 170, 170, 0.178);

    max-height: 45px;
    min-height: 15px;
    min-width: 30px;
    display: flex;
    align-items: center;

    //   justify-content: center;
    background-color: transparent;

    img {
      // width: 75%;
      //  min-width: 100px;
      // max-width: 198px;
      top: 0;
      max-width: 190px;

      position: absolute;
      max-width: 190px;
      max-height: 50px;
      min-height: 15px;
      min-width: 30px;

      // filter: invert(1);
      //     width: 110px;
    }
  }
  @media (max-width: 1200px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 48px;
      min-height: 20px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;

      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 48px;
        min-height: 20px;
        min-width: 25px;

        max-width: 160px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 992px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 48px;
      min-height: 35px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;

      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 48px;
        min-height: 35px;
        min-width: 25px;

        max-width: 135px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 768px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 40px;
      min-height: 35px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 40px;
        min-height: 35px;
        min-width: 25px;

        max-width: 135px;

        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
}

.titlezone {
  height: calc(100% - 270px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  .nameentreprise {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    p {
      writing-mode: vertical-lr;
      text-orientation: mixed;
      transform: rotate(180deg);
      font-family: "Poppins";
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #cfcfcf;
    }
  }
}

.choose {
  height: 20.6vh;
  max-height: 165px;
  min-height: 135px;
  position: absolute;
  top: calc(10vh + 25px);
  z-index: 1;
  padding: 0;
  margin: 0;
  .choosetype {
    height: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 0.6vh;
    .type {
      height: 33%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      h2 {
        font-family: "Poppins";
        font-size: calc(0.6vw + 1.7rem);
        font-weight: 400;
        padding: 0;
        margin: 0;
        color: #9c9c9c;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h2:hover {
        font-weight: 500;
        color: black;
      }
      .selected {
        font-weight: 500;
        color: black;
      }
    }
  }
  .choosetypemobile {
    height: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 0.6vh;
    a {
      height: 33%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      h2 {
        font-family: "Poppins";
        font-size: calc(0.6vw + 1.7rem);
        font-weight: 400;
        padding: 0;
        margin: 0;
        color: #9c9c9c;
      }
      h2:hover {
        font-weight: 500;
        color: black;
      }
      .selected {
        font-weight: 500;
        color: black;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .choose {
    height: 20vh !important;
    left: -10px !important;
    flex: 0 0 110%;
    max-width: 110%;
    .choosetype {
      flex: 0 0 120%;
      max-width: 120%;
      .type {
        cursor: pointer;
        h2 {
          font-size: calc(0.7vw + 1.4rem) !important;
        }
      }
    }
  }
}

.choosetypemobile {
  display: none;
}
@media (max-width: 576px), (max-height: 576px) {
  .choose {
    max-height: 100px;
    position: fixed;
    z-index: 10;
    padding: 0;
    width: 100%;
    margin: auto;
    top: calc(100vh - 110px) !important;
    left: 0 !important;
    margin: 0;
    bottom: 0;
    .choosetype {
      display: none;
    }

    .choosetypemobile {
      display: flex;
      padding: 0;
      padding-bottom: 0;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 80px;
      position: fixed;
      bottom: 30px;
      .type {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: calc(0.7vw + 1.4rem) !important;
          text-align: center;
          font-family: "Poppins";

          color: #9c9c9c;
        }
        .selected {
          color: black;
        }
      }
    }
  }
}
.contactzone {
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  height: 100px;
  @media (max-width: 1450px) {
    .contact {
      width: 170px !important;
    }
  }
  @media (max-width: 1200px) {
    .contact {
      width: 150px !important;
    }
  }
  @media (max-width: 992px) {
    .contact {
      width: 100px !important;
      img {
        width: 140px;
      }
    }
  }
  .contact {
    position: absolute;
    bottom: 30px;
    left: 0;

    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: flex-end;
    .contactus {
      height: 100%;
      display: flex;

      align-items: center;
      text-transform: uppercase;
      font-size: 1.5rem;
      right: 0;
      width: 100%;
      img {
        height: 40px;
        right: 0;
        margin-right: 1px;
        filter: invert(1);
      }
      .hblogo{
        filter: invert(0);
      }
    }
  }
}

@media (max-width: 576px), (max-height: 576px) {
  .infoentreprise {
    position: fixed;
    left: 25px;
    top: 15px;
    z-index: 15;
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);

      max-height: 40px;
      min-height: 30px;
      min-width: 25px;
      display: flex;
      align-items: center;

      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      a {
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        max-height: 40px;
        min-height: 30px;
        min-width: 25px;

        max-width: 135px;
        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  .contact {
    display: none;
  }
}
